import * as React from "react";
import {
  Typography,
  Button,
  Divider,
  Paper,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";

import {
  CreateAgreementToSpots,
  CreateAgreementToChassisRental,
} from "../../../services/PaymentStripeServices";
import Cookies from "universal-cookie";

import DetailSummaryInnerTable from "./DetailSummaryInnerTable";
import DetailSummaryNotes from "./DetailSummaryNotes";
import DetailSummaryFooter from "./DetailSummaryFooter";
import TermsOfUseDialog from "./TermsOfUseDialog";
import RentalAgreementDialog from "./RentalAgreementDialog";
import PaymentPage from "../../StripeEmbedd/PaymentPage/PaymentPage";
import "./DetailSummary.css";

import { useSnackBar } from "../../../context/SnackBarContext";
import {
  getItemFromLocalStorage,
  editItemInLocalStorage,
  updateItemInCart,
} from "../../../services/localStorageService";

const DetailSummaryCard = ({ activeProduct, onActiveProductChange }) => {
  const cookies = new Cookies();
  const [paymentState, setPaymentState] = React.useState(false);
  const [processingAgreement, setProcessingAgreement] = React.useState(false);
  const [agreementResponse, setAgreementResponse] = React.useState(null);
  const [localActiveProduct, setLocalActiveProduct] = React.useState({});
  const { handleRenderSnackBar } = useSnackBar();

  const handleChangePrivacy = (privacyPolicyAgreed) => {
    activeProduct.product.privacyPolicyAgreed = privacyPolicyAgreed;

    onActiveProductChange(activeProduct.product, activeProduct.index);
    updateItemInCart("yardProducts", activeProduct.index, {
      ...activeProduct.product,
    });
    window.dispatchEvent(new Event("localStorageHasChanged"));
  };

  //FUNCTIONS ----------------------------------------------------------------------------------------

  function handleSetAgreementObjectStructure() {
    const sessionCosmos = cookies.get("sessionCosmos");
    localActiveProduct.UserRequest = sessionCosmos.email[0];
    localActiveProduct.CarrierClient = sessionCosmos.scac_code;
    delete localActiveProduct.EndDate;
    return {
      payload: localActiveProduct,
    };
  }

  function handleSetAgreementObjectStructureToChassisRental() {
    const sessionCosmos = cookies.get("sessionCosmos");
    localActiveProduct.UserRequest = sessionCosmos.email[0];
    localActiveProduct.CarrierClient = sessionCosmos.scac_code;

    // Cambiar el nombre de YardSelected a ChassisSelected
    localActiveProduct.ProductsChosen.ChassisSelected =
      localActiveProduct.ProductsChosen.YardSelected;
    delete localActiveProduct.ProductsChosen.YardSelected;

    delete localActiveProduct.EndDate;
    delete localActiveProduct.itemType;
    return {
      payload: localActiveProduct,
    };
  }

  function handleSetPaymentObjectInLocalStorage(response, itemProduct) {
    const existingPaymentObjects = getItemFromLocalStorage("paymentObject");
    const newPaymentObject = {
      response,
      itemProduct,
    };
    function arePaymentObjectsEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    let updatedPaymentObjects;

    if (existingPaymentObjects) {
      const objectExists = existingPaymentObjects.some((obj) =>
        arePaymentObjectsEqual(obj, newPaymentObject)
      );

      if (objectExists) {
        return;
      }
      updatedPaymentObjects = [...existingPaymentObjects, newPaymentObject];
    } else {
      updatedPaymentObjects = [newPaymentObject];
    }
    editItemInLocalStorage("paymentObject", updatedPaymentObjects);

    window.dispatchEvent(new Event("paymentObjectCreated"));
  }

  function handleCreateAgreement() {
    let data;
    setProcessingAgreement(true);
    if (activeProduct.product.itemType === "Chassis") {
      data = handleSetAgreementObjectStructureToChassisRental();
      CreateAgreementToChassisRental(data).then((response) => {
        if (!response.firstPaymentIds) {
          setProcessingAgreement(false);
          handleRenderSnackBar(response.data, "error");
          return;
        }
        setAgreementResponse(response);
        setPaymentState(true);
        setProcessingAgreement(false);
      });
    } else {
      data = handleSetAgreementObjectStructure();
      CreateAgreementToSpots(data).then((response) => {
        if (!response.firstPaymentIds) {
          setProcessingAgreement(false);
          handleRenderSnackBar(response.data, "error");
          return;
        }
        setAgreementResponse(response);
        setPaymentState(true);
        setProcessingAgreement(false);
      });
    }
  }

  function checkIfPaymentExistInLocalStorage() {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    if (paymentObject !== null || undefined) {
      return paymentObject;
    }
  }

  //USE EFFECTS ----------------------------------------------------------------------------------------

  React.useEffect(() => {
    const paymentExist = checkIfPaymentExistInLocalStorage();
    if (paymentExist) {
      setPaymentState(true);
      setAgreementResponse(paymentExist[0].response);
    }
  }, []);

  React.useEffect(() => {
    if (!activeProduct.product.UserRequest) {
      setLocalActiveProduct(JSON.parse(JSON.stringify(activeProduct.product)));
    }
  }, [activeProduct]);

  React.useEffect(() => {
    if (activeProduct.product.EndDate && paymentState) {
      handleSetPaymentObjectInLocalStorage(
        agreementResponse,
        activeProduct.product
      );
    }
  }, [paymentState]);

  const activeProductAgreementSigned = activeProduct.product.idEnvelope
    ? true
    : false;

  const activeProductPrivacyPolicyAgreed =
    activeProduct.product.privacyPolicyAgreed;

  const error =
    !activeProductPrivacyPolicyAgreed || !activeProductAgreementSigned;

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          minHeight: "60vh",
          alignContent: "center",
          borderRadius: 5,
          bgcolor: "white.main",
          zIndex: "bottom",
          px: 3,
          py: 2,
        }}
      >
        {processingAgreement ? (
          <Box className="LoginReact-circular-progress-box">
            <CircularProgress
              thickness={7}
              className="LoginReact-circular-progress"
            />
          </Box>
        ) : (
          <>
            {paymentState ? (
              <PaymentPage AgreementResponse={agreementResponse} />
            ) : (
              <>
                <Typography
                  color="primary.dark"
                  style={{
                    fontSize: 26,
                    fontWeight: "bold",
                  }}
                >
                  Summary for{" "}
                  {activeProduct.product.ProductsChosen.YardSelected.CodeName}
                </Typography>
                <Divider
                  variant="fullWidth"
                  sx={{ borderColor: "primary.dark", border: 1 }}
                />
                <DetailSummaryInnerTable
                  activeProduct={activeProduct.product}
                />
                <DetailSummaryNotes />
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <FormControl
                    required
                    error={error}
                    component="fieldset"
                    sx={{ m: 0, width: "100%" }}
                    variant="filled"
                  >
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              color: "#6C6B6B",
                            }}
                            checked={
                              activeProduct.product.privacyPolicyAgreed
                                ? true
                                : false
                            }
                            name="termsOfUsePolicy"
                            onClick={() => {
                              window.dispatchEvent(
                                new Event("openPrivacyPolicy")
                              );
                            }}
                          />
                        }
                        label={
                          <TermsOfUseDialog
                            activeProduct={activeProduct}
                            onPrivacyPolicyChange={handleChangePrivacy}
                          />
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              color: "#6C6B6B",
                            }}
                            checked={
                              activeProduct.product.idEnvelope ? true : false
                            }
                            disabled={
                              activeProduct.product.idEnvelope ? true : false
                            }
                            name="rentalAgreementPolicy"
                          />
                        }
                        label={
                          <RentalAgreementDialog
                            activeProduct={activeProduct}
                            onActiveProductChange={onActiveProductChange}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: error ? "space-between" : "flex-end",
                    alignItems: "center",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    my: 2,
                  }}
                >
                  {error && (
                    <Typography
                      color="secondary"
                      style={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      Please approve the terms and contract to proceed
                    </Typography>
                  )}
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled={error}
                    onClick={() => handleCreateAgreement()}
                    sx={{ width: 150, borderRadius: 2 }}
                  >
                    <Typography textTransform="capitalize" variant="button">
                      Buy now
                    </Typography>
                  </Button>
                </Box>

                <Divider
                  variant="fullWidth"
                  sx={{ borderColor: "primary.dark", border: 1 }}
                />
                <DetailSummaryFooter />
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default DetailSummaryCard;
