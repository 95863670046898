import * as React from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import DeleteIcon from "@mui/icons-material/Delete";

import OrderSummaryCardTitle from "./OrderSummaryCardTitle";
import OrderSummaryInnerTable from "./OrderSummaryInnerTable";

import {
  editItemInLocalStorage,
  getItemFromLocalStorage,
  removeItemFromCart,
} from "../../../services/localStorageService";

const OrderSummaryCard = ({
  index,
  item,
  activeProduct,
  onViewClick,
  onRemoveClick,
  paymentProcessed,
  cleanActiveProduct,
}) => {
  const [cardStateInPaymentProcess, setCardStateInPaymentProcess] =
    React.useState(false);
  const [paymentCompleted, setPaymentCompleted] = React.useState(false);
  const [isSomethingInPaymentProcess, setIsSomethingInPaymentProcess] =
    React.useState(false);
  const [typeOfStatus, setTypeOfStatus] = React.useState("");

  function checkIsPaymentInProgress() {
    const paymentObject = getItemFromLocalStorage("paymentObject");

    return paymentObject
      ? setIsSomethingInPaymentProcess(true)
      : setIsSomethingInPaymentProcess(false);
  }

  window.addEventListener("responseStripeCompleted", (e) => {
    setPaymentCompleted(true);
    setTypeOfStatus(e.detail.status);
  });

  function arePaymentObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  function checkIfItemIsInPaymentProcess() {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    return paymentObject &&
      arePaymentObjectsEqual(paymentObject[0].itemProduct, item)
      ? setCardStateInPaymentProcess(true)
      : setCardStateInPaymentProcess(false);
  }

  function handleDeleteItemFromCart() {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    const cart = getItemFromLocalStorage("yardProducts");
    if (!paymentObject) return;
    else if (
      paymentObject[0].paymentStatus === "completed" ||
      paymentObject[0].paymentStatus === "created"
    ) {
      cart.map((cartItem) => {
        if (arePaymentObjectsEqual(cartItem, paymentObject[0]?.itemProduct)) {
          const index = cart.indexOf(cartItem);
          cart.splice(index, 1);
        }
      });
      editItemInLocalStorage("yardProducts", cart);
      removeItemFromCart("paymentObject");
      window.dispatchEvent(new Event("localStorageHasChanged"));
    }
  }

  function handleOnViewClick() {
    handleDeleteItemFromCart();
    cleanActiveProduct();
    onViewClick(item);
    setPaymentCompleted(false);
    setCardStateInPaymentProcess(false);
  }

  React.useEffect(() => {
    checkIfItemIsInPaymentProcess();
    checkIsPaymentInProgress();

    window.addEventListener("paymentObjectCreated", (event) => {
      checkIfItemIsInPaymentProcess();
      checkIsPaymentInProgress();
    });
    window.addEventListener("popstate", () => {
      handleDeleteItemFromCart();
    });
  });

  return (
    <Paper
      elevation={2}
      sx={{
        minHeight: 100,
        borderRadius: 5,
        border: activeProduct ? 3 : undefined,
        borderColor: (() => {
          if (activeProduct) {
            if (paymentCompleted) return "#22c25e";
            else {
              return cardStateInPaymentProcess ? "yellow" : "secondary.main";
            }
          } else {
            return "undefined";
          }
        })(),
        bgcolor: "white.main",
        zIndex: "bottom",
        px: 3,
        py: 2,
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: 1,
          gridTemplateRows: "auto",
          gridTemplateAreas: `"header header header header"
  "main main main actions"`,
        }}
      >
        <Box sx={{ gridArea: "header" }}>
          <OrderSummaryCardTitle
            typeOfProduct={item.itemType}
            text={item.ProductsChosen.YardSelected.CodeName}
            type={item.ProductsChosen.PriceChosen.Frequency}
            spots={
              item.ProductsChosen.NumberOfSpots === 0
                ? 1
                : item.ProductsChosen.NumberOfSpots
            }
            inPayment={cardStateInPaymentProcess}
            paymentState={paymentCompleted}
            status={typeOfStatus}
          />
          <Divider
            variant="fullWidth"
            sx={{ borderColor: "primary.dark", border: 1 }}
          />
        </Box>
        <Box sx={{ gridArea: "main" }}>
          <OrderSummaryInnerTable
            priceChosen={item.ProductsChosen.PriceChosen}
          />
        </Box>
        <Box
          sx={{
            gridArea: "actions",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {cardStateInPaymentProcess ? null : (
            <>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                disabled={
                  (activeProduct && !paymentProcessed) ||
                  (isSomethingInPaymentProcess &&
                    !cardStateInPaymentProcess &&
                    !paymentCompleted)
                }
                onClick={() => handleOnViewClick()}
                sx={{ borderRadius: 2 }}
              >
                <Typography textTransform="capitalize" variant="button">
                  View
                </Typography>
              </Button>
              <Button
                size="small"
                color="black"
                disabled={
                  isSomethingInPaymentProcess &&
                  !cardStateInPaymentProcess &&
                  !paymentCompleted
                }
                onClick={() => onRemoveClick(index)}
                startIcon={<DeleteIcon />}
              >
                <Typography textTransform="capitalize" variant="button">
                  Remove
                </Typography>
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default OrderSummaryCard;
