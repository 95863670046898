import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import OrderSummaryCard from "../components/CartView/OrderSummary/OrderSummaryCard";
import DetailSummaryCard from "../components/CartView/DetailSummary/DetailSummaryCard";
import PaymentProcessed from "../components/StripeEmbedd/PaymentProcessed/PaymentProcessed";
import { getItemFromLocalStorage } from "../services/localStorageService";

const CartViewContainer = ({ userCart, onRemoveItemFromCart }) => {
  const [activeProduct, setActiveProduct] = React.useState(null);
  const [paymentProcessed, setPaymentProcessed] = React.useState(false);
  const handleViewClick = (itemToView) => {
    const cart = getItemFromLocalStorage("yardProducts");
    cart.map((item, index) => {
      if (arePaymentObjectsEqual(item, itemToView)) {
        setActiveProduct({ product: item, index });
      }
    });

    const url = window.location.href;
    if (url.includes("AgreementId")) {
      window.history.pushState({}, null, "/landing-page/cart-products");
      setPaymentProcessed(false);
    }
  };
  const handleActiveProductChange = (product, index) => {
    setActiveProduct({ product: product, index: index });
  };
  const handleRemoveClick = (index) => {
    const newCart = userCart.filter((_, i) => i !== index);
    const newActiveIndex = index === 0 ? 0 : index - 1;
    setActiveProduct({
      product: newCart[newActiveIndex],
      index: newActiveIndex,
    });

    onRemoveItemFromCart(index);
    window.dispatchEvent(new Event("itemOfProductsRemoved"));
  };

  function handleCleanActiveProduct() {
    setActiveProduct(null);
  }

  function arePaymentObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  function EvaluateIfPaymentExist() {
    const url = window.location.href;
    const paymentObject = getItemFromLocalStorage("paymentObject");
    if (url.includes("AgreementId") && paymentObject) {
      setPaymentProcessed(true);
    }
    if (paymentObject) {
      const cart = getItemFromLocalStorage("yardProducts");
      cart.map((item, index) => {
        if (arePaymentObjectsEqual(item, paymentObject[0].itemProduct)) {
          setActiveProduct({ product: item, index });
        }
      });
    } else {
      setActiveProduct({ product: userCart[0], index: 0 });
    }
  }
  React.useEffect(() => {
    EvaluateIfPaymentExist();
  }, []);

  return activeProduct === null ? null : (
    <Box sx={{}}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8 }}>
        <Grid
          xs={4}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <ShoppingCartIcon color="primary.dark" sx={{ fontSize: 35, mr: 1 }} />
          <Typography
            color="primary.dark"
            style={{
              fontSize: 60,
              fontWeight: "bold",
            }}
          >
            Order Summary
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 8 }}>
        <Grid xs={4} md={4} sx={{ flexDirection: "column" }}>
          {userCart.map((product, index) => (
            <OrderSummaryCard
              key={product.ProductsChosen.YardSelected.idCosmos}
              item={product}
              activeProduct={activeProduct.index === index}
              active={product === activeProduct.product}
              onViewClick={() => handleViewClick(product)}
              onRemoveClick={() => handleRemoveClick(index)}
              paymentProcessed={paymentProcessed}
              cleanActiveProduct={handleCleanActiveProduct}
            />
          ))}
        </Grid>

        <Grid xs={4} sm={4}>
          {paymentProcessed ? (
            <PaymentProcessed />
          ) : (
            <DetailSummaryCard
              activeProduct={activeProduct}
              onActiveProductChange={handleActiveProductChange}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CartViewContainer;
